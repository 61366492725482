#background-wrap {
	z-index: -1;
	display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-size: 100%;
    background-color: #f2f2f2;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}