.middleColumn{
    display: flex;
    flex-direction: column;

    .headerText{
        font-family: "Silverado Light";
        font-size: 32px;
        line-height: 1;
    }

    .indent{
        height: 50px;

        @media screen and (max-width: 800px){
            display:none;            
        }
    }
    .indent_second{
        height: 20px;

        @media screen and (max-width: 800px){
            display:none;            
        }
    }
}