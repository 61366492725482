.panelHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Silverado Light';
    flex-direction: column;
    
    font-size: 24px;

    div.headerText {
        width: 100%;
        vertical-align: middle;
        text-align: center;
        font-size: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.image{
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../../assets/images/skullCrownBlk.png);
            aspect-ratio: 258 / 136;
            width: 55px;
            margin-bottom: 10px;
            margin-top: 5px;

        }
        &.second{
            margin-top: -10px;
            font-size: 24px;
        }
    }
}