.backdrop{
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
}

.modal{
    position: fixed;
    z-index: 1040;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;

    &:focus-visible{
        outline: 0px;
    }
}

.modalInner{
    width: auto;
    height: auto;
}