.loaderContainer{
    width: 100%;
    height: 100%;
    z-index: 100005;
    background-color: rgba(0, 0, 0, 0.345);
    position: absolute;
    top: 0;
    left: 0;

    .loaderInnerContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    
        .loader {
            width: 48px;
            height: 48px;
            border: 5px solid #FFF;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
            }
        
        
            @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        } 
    }
}


.goToAccountContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.dialogContainer{
    margin-top:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}
