.backgroundImageDiv {
    flex: 1;
    align-items: center;
    align-content: center;
    .downloadLink {
        margin-top: 0px;
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        text-decoration: none;
        color: white !important;
        border-radius: 3px;
        padding: 10px;
        border: 0px solid gray;
        background-color: teal;
        margin-bottom: 5px;

        div:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 26px;
            height: 26px;
        }
        &:hover {
            color: white;
            background-color: #009095;
        }
    }

    div.otherDownloadContainer{
        text-align: center;
    }

    a.otherDownload {
        color: teal;

        &:hover {
            color: gray;
        }

        &:visited() {
            color: teal;
        }
    }
}