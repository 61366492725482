.indent-registration {
    height: 10%;
    min-height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
        height: 14%;
    }
}

.house-key-style{
    height: 160px;
    @media screen and (max-width: 800px) {
        height: 100px;
    }
}

.registration-main-page {
    opacity: 1;
    width: 98% !important;

    a {
        color: teal;

        &:visited {
            color: teal;
        }

        &:hover {
            color: teal;
        }
    }

    .row-registration {
        margin: auto;
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        overflow: hidden;

        &:after {
            visibility: hidden;
            display: block;
            font-size: 0;
            content: ' ';
            clear: both;
            height: 0;
        }

        .test {
            &::before {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                content: '';
                background-image: url('https://storage.googleapis.com/database-of-death-bucket/Images/backgroundcenter.png');
                opacity: 0.4;
                object-fit: cover;
                object-position: top left;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .column {
            padding: 10px;
            flex: 1;

            &.column-first {
                order: 0;
                flex: 0.6;
             

                &.background{
                    background-image: url(https://storage.googleapis.com/database-of-death-bucket/Images/backgroundcenter.png);
                    opacity: 0.4;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                }

                .column-first-content {
                    float: right;
                    width: 80%;
                    margin-right: 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    hr {
                        width: 100%;
                    }
                }
            }

            &.column-registration {
                width: 40%;
                padding: 10px;
                order: 1;
                flex: 0.4;
            }
        }

        @media screen and (max-width: 800px) {
            .column {
                width: 100%;
            }

            .column-first {
                display: none;
            }

            .column-registration {
                width: 100% !important;
                flex: 1 1 !important;
                order: 0 !important;
            }
        }
    }

    .row:after {
        content: '';
        display: table;
        clear: both;
    }

    .register-panel {
        background-color: white;
        padding: 40px 42px;
        margin-bottom: 10px;
        box-shadow: 0 1px 5px #c9c9c9;
        min-height: 60vh;

        &.success{
            min-height: 60vh;
            display: flex;
            flex-direction: column;

            .middleContainer{
                height: 100%;
                flex-grow: 1;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                justify-items: center;
                margin-bottom: 100px;
            }
        }

        @media screen and (max-width: 800px) {
            padding: 40px 10px;
        }
    }

    .background-image-div {
        flex: 1;
        align-items: center;
        align-content: center;
        .download-link {
            margin-top: 0px;
            -webkit-appearance: button;
            -moz-appearance: button;
            appearance: button;
            text-decoration: none;
            color: white !important;
            border-radius: 3px;
            padding: 10px;
            border: 0px solid gray;
            background-color: teal;
            margin-bottom: 5px;

            div:first-child {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            img {
                width: 26px;
                height: 26px;
            }
            &:hover {
                color: white;
                background-color: #009095;
            }
        }

        a.other-download {
            color: teal;

            &:hover {
                color: gray;
            }

            &:visited() {
                color: teal;
            }
        }
    }

    .img-preview {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        object-position: top center;
    }

    .popup-dark {
        background: black;
        color: white;
    }

    hr.divider {
        margin-top: 40px;
        margin-bottom: 40px;

        @media screen and (max-width: 800px) {
            margin-top: 0px;
            margin-bottom: 0px;;
        }
    }

    .logo-header {
        height: 30px;
        line-height: 60px;
        svg {
            height: 36px;
        }

        span {
            font-weight: 600;
            height: 100%;
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
            font-size: 24px;
            font-family: 'Silverado Light';
            margin-top: -20px;
            color: black;
        }
    }

    .info-message {
        color: black !important;
    }

    .privacy-container-panel {
        margin-top: 60px;
        a{
            margin-left: 8px;
        }
    }
}
