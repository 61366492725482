#page-auth-wrapper {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001; /* Specify a stack order in case you're using a different order for other elements */
    background-color: white;
    text-align: center;

    @media screen and (max-width: 800px) {
        position: relative;
    }

    .panel-header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Silverado Light';
        font-size: 24px;
        margin-top: 15px;

        div.headerText {
            height: 36px;
            font-size: 36px;
        }

        svg {
            height: 48px;
        }
    }

    .indent {
        height: 20%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .header-container {
            width: 400px;
        }

        .login-header {
            font-size: 34px;
            margin-bottom: 0px;
            margin-top: 20px;
        }

        .info-message {
            font-size: 13px;
            font-weight: bold;
            color: gray;
            margin-top: 0px;
        }
    }

    .page-main {
        width: 400px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        left: 0;
        position: absolute;
        z-index: 10001;
        transition: 300ms;
        -webkit-transition: 300ms;

        &.success {
            height: auto;
            min-height: fit-content;
        }
    }

    .middle-container {
        width: 100%;
        margin: auto;

        .input-row {
            margin-top: 10px;
        }

        .terms-row {
            text-align: left;
            font-size: 16px;
            height: 20px;
            line-height: 20px;

            label {
                display: inline-block;
                padding-right: 10px;
                white-space: nowrap;

                input {
                    vertical-align: middle;
                }
                span {
                    vertical-align: middle;
                }
            }

            a {
                color: teal;
                &:hover {
                    color: teal;
                }
            }
            input {
                margin-right: 5px;
                height: 16px;
                width: 16px;
            }
        }

        h3 {
            text-align: center;
        }

        input[type='email'],
        input[type='password'] {
            width: 100%;
            height: 42px;
            box-sizing: border-box;
            border: 1px solid gray;
            border-radius: 3px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            outline: 0px;
            outline-color: transparent;
            outline-style: solid;
            padding: 10px;

            &.has-error {
                outline-color: red !important;
                outline-width: 2px;
                border-color: red !important;
            }

            &:focus {
                outline-color: teal;
                outline-width: 2px;
                border-color: teal;

                &.has-error {
                    outline-color: red !important;
                    outline-width: 2px;
                    border-color: red !important;
                }
            }
        }

        .success-row {
            h3 {
                color: teal;
                text-align: left;
            }
            p {
                color: gray;
            }
        }

        .error-container {
            display: flex;
            align-items: center;
            height: 100%;
            svg {
                display: inline-block;
            }
            p {
                color: red;
                margin: 0px;
                font-size: 14px;
                line-height: 14px;
                display: inline-block;
                margin-left: 4px;
            }

            &.grid {
                display: grid;
                grid-template-columns: 14px auto;
                gap: 0px 0px;
                grid-auto-flow: row;
            }
        }

        .button-container {
            input[type='button'] {
                width: 100%;
                height: 42px;
                color: #fff;
                background-color: teal;
                border-radius: 3px;
                border: 0px;
                padding: 10px;
                &:disabled {
                    background-color: gray;
                }
            }
        }

        .login-header {
            font-size: 34px;
            margin-bottom: 0px;
            margin-top: 20px;
        }

        .info-message {
            font-size: 13px;
            font-weight: bold;
            color: gray;
            margin-top: 0px;
        }

        .forgot-password-section {
            margin-top: 20px;
            * {
                margin: 0px;
                margin-top: 4px;
            }
            a {
                color: teal;

                &:visited {
                    color: teal;
                }

                &:hover {
                    color: teal;
                }
            }
        }

        .bottom-container {
            position: absolute;
            bottom: 0px;
            right: 20px;
            a {
                float: right;
                margin: 0px 5px;
                color: gray;

                &:visited {
                    color: gray;
                }

                &:hover {
                    color: lightgray;
                }
            }
        }
    }

    .middle-container > #login-error {
        margin: 0px;
        display: none;
        color: red;
        text-align: center;
    }

    .privacy-container {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0px);
        a {
            margin-left: 10px;
        }
    }

    button.link {
        background: none !important;
        border: none;
        padding: 0 !important;

        /*optional*/
        font-family: arial, sans-serif; /*input has OS specific font-family*/
        color: #069;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
