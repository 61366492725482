.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner {
        .loading_container {
            position: relative;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h1 {
            color: white;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
}

.box {
    background: transparent;
    width: 300px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    transition: transform 0.2s;

    .percent {
        width: 150px;
        height: 150px;
        position: relative;

        svg {
            width: 150px;
            height: 150px;
            position: relative;

            circle {
                width: 150px;
                height: 150px;
                fill: none;
                stroke-width: 10;
                transform: translate(5px, 5px);
                stroke-dasharray: 440;
                stroke-dashoffset: 440;
                stroke-linecap: round;
            }

            circle:nth-child(1) {
                stroke-dashoffset: 0;
                stroke: #525252;
            }

            circle:nth-child(2) {
                stroke: white;
            }
        }

        .num {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            color: #111;

            p {
                font-size: 48px;
                color: white;
            }

            p span {
                font-size: 24px;
            }
        }
    }

    .text {
        padding: 10px 0 0;
        color: white;
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 20px;
    }
}
