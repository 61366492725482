@font-face {
    font-family: "Silverado Light";
    src: local("Silverado Light"),
     url("./assets/fonts/SVCL____.TTF") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Silverado Medium";
    src: local("Silverado Medium"),
     url("./assets/fonts/SVCM____.TTF") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Silverado ExtraBold";
    src: local("Silverado ExtraBold"),
     url("./assets/fonts/SVCE____.TTF") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Silverado Bold";
    src: local("Silverado Bold"),
     url("./assets/fonts/SVCB____.TTF") format("truetype");
    font-weight: bold;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
